var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "12", sm: "8" } },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-space-between" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      attrs: { color: "primary" },
                      on: { click: _vm.closeDetail },
                    },
                    [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                    1
                  ),
                  this.$store.getters.getRole == "operation" ||
                  this.$store.getters.getRole == "developer"
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.redirectTaskEdit },
                        },
                        [_vm._v(" Edit ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "pa-3", attrs: { "elevation-1": "" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "text-h4 ",
                      attrs: { color: "secondary white--text", flat: "" },
                    },
                    [_vm._v(" Task ")]
                  ),
                  _vm.api.isLoading
                    ? _c("v-skeleton-loader", {
                        ref: "skeleton",
                        attrs: { type: "table" },
                      })
                    : _vm._e(),
                  _vm.task != null
                    ? _c(
                        "v-container",
                        [
                          _c("div", { staticClass: "mt-3 font-weight-bold " }, [
                            _vm._v(" " + _vm._s(this.task.task_name) + " "),
                          ]),
                          _c(
                            "div",
                            { staticClass: "mt-3 d-flex justify-start" },
                            [
                              _vm._v(" Company : "),
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "PageCompaniesDetail",
                                      params: { id: this.task.company_id },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(this.task.company_name) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "my-4" }),
                          _c(
                            "div",
                            { staticClass: "mt-3" },
                            [
                              _c("v-select", {
                                attrs: {
                                  disabled: "",
                                  dense: "",
                                  outlined: "",
                                  label: "Status",
                                  items: _vm.optionTaskStatus,
                                },
                                on: { change: _vm.updateTaskStatus },
                                model: {
                                  value: _vm.task.task_status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.task, "task_status", $$v)
                                  },
                                  expression: "task.task_status",
                                },
                              }),
                              _vm.api.isLoading
                                ? _c("v-progress-circular", {
                                    attrs: {
                                      size: 50,
                                      color: "primary",
                                      indeterminate: "",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-3" },
                            [
                              _vm._v(" Priority : "),
                              this.task.priority == "Urgent"
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass: "font-weight-bold",
                                      attrs: { color: "red white--text" },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(this.task.priority) + " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "v-chip",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(this.task.priority) + " "
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-3 d-flex justify-start" },
                            [
                              _vm._v(" Category : "),
                              _c(
                                "p",
                                { staticClass: "ml-3 font-weight-bold" },
                                [_vm._v(_vm._s(this.task.task_category))]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-3 d-flex justify-start" },
                            [
                              _vm._v(" Assigned at : "),
                              _c(
                                "p",
                                { staticClass: "ml-3 font-weight-bold" },
                                [_vm._v(_vm._s(this.task.created_at))]
                              ),
                            ]
                          ),
                          _vm.task.deadline_at != null
                            ? _c(
                                "div",
                                { staticClass: "mt-3 d-flex justify-start" },
                                [
                                  _vm._v(" Deadline : "),
                                  _c(
                                    "p",
                                    { staticClass: "ml-3 font-weight-bold" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          this.task.deadline_at.split(" ")[0]
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "mt-3" },
                            [
                              _c("p", [_vm._v(" Action ")]),
                              this.task.task_action != null
                                ? _c("mavon-editor", {
                                    staticStyle: {
                                      "z-index": "0",
                                      "min-height": "100px",
                                      height: "auto",
                                      width: "100%",
                                    },
                                    attrs: {
                                      width: "auto",
                                      defaultOpen: "preview",
                                      toolbarsFlag: false,
                                      subfield: false,
                                      language: "en",
                                      editable: false,
                                    },
                                    model: {
                                      value: this.task.task_action,
                                      callback: function ($$v) {
                                        _vm.$set(this.task, "task_action", $$v)
                                      },
                                      expression: "this.task.task_action",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }